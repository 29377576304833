import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"policy"} />
		<Helmet>
			<title>
				ВелоГеній
			</title>
			<meta name={"description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:title"} content={"ВелоГеній"} />
			<meta property={"og:description"} content={"Ласкаво просимо до ВелоГеній — майстерні для справжніх фанатів велосипедів!\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22b6e5d277001897f3b4/images/6555144.png?v=2024-09-20T10:02:55.402Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
		</Components.Header>
		<Components.Header2 />
		<Section padding="80px 0" sm-padding="40px 0" background="--color-light">
			<Override slot="SectionContent" max-width="800px" margin="0 auto" />
			{"    "}{"    "}
			<Text as="h1" font="--headline1" margin="20px 0 40px 0" text-align="center">
				{"        "}Політика конфіденційності{"\n    "}
			</Text>
			{"    \n    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Ця політика конфіденційності описує, як велосипедна майстерня "ВелоГеній" (далі "ми", "нас", або "наш") збирає, використовує та захищає інформацію, яку ви надаєте під час користування нашим вебсайтом та послугами. Ми серйозно ставимося до вашої конфіденційності і зобов’язуємося захищати вашу персональну інформацію.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}1. Інформація, яку ми збираємо{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Ми можемо збирати таку інформацію:{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Особисті дані: ім'я, електронна пошта, номер телефону, адреса для зв'язку, інша інформація, яку ви надаєте під час заповнення форм на нашому вебсайті.{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Дані про використання: інформація про ваші відвідування нашого вебсайту, включаючи IP-адресу, тип браузера, час доступу та сторінки, які ви переглядаєте.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}2. Використання інформації{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Ми використовуємо зібрану інформацію для таких цілей:{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Надання та підтримка наших послуг.{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Зв'язок з вами щодо замовлень, послуг або запитів.{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Поліпшення нашого вебсайту та послуг.{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="10px 0 10px 20px">
				{"        "}• Аналіз даних про відвідування сайту для покращення користувацького досвіду.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}3. Захист даних{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Ми вживаємо відповідних заходів для захисту вашої інформації від несанкціонованого доступу, розкриття або знищення. Ми використовуємо надійні методи шифрування та захисту даних.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}4. Файли cookies{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Наш вебсайт може використовувати файли cookies для покращення вашого досвіду. Ви можете відмовитися від використання файлів cookies у налаштуваннях вашого браузера, однак це може вплинути на функціональність вебсайту.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}5. Зміни до політики{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Ми можемо оновлювати цю політику конфіденційності з часом. Ми повідомимо вас про будь-які зміни, опублікувавши нову версію на цій сторінці.{"\n    "}
			</Text>
			{"    "}
			<Text as="h2" font="--headline2" margin="20px 0">
				{"        "}6. Зв'язок з нами{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0">
				{"        "}Якщо у вас є запитання щодо цієї політики конфіденційності, будь ласка, зв'яжіться з нами за допомогою форми на нашому вебсайті або за телефоном, вказаним у розділі "Контакти".{"\n    "}
			</Text>
		</Section>
		<Components.Footernew>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link4" />
		</Components.Footernew>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});